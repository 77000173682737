export enum EOtherIncomeTypes {
  familyAllocations = 1,
  workIncidentIndemnisation = 3,
  maternityPaternityIncome = 4,
  other = 6,
  retirementIncome = 8,
  disabilityIncome = 11,
}

export const EOtherIncomeTypeList = Object.values(EOtherIncomeTypes).filter((x) => typeof x === 'number')

export enum EDefaultPaymentPlanIdEnum {
  RegularDailyInterests = 'regularInterest',
  RegularPeriodInterests = 'regularPeriod',
}

export enum EPaymentFrequency {
  BiWeekly = 'biWeekly',
  Monthly = 'monthly',
}

export enum EReviewLink {
  Medicard = 'https://g.page/r/CYpoFarvZlYMEBI/review',
  IFinance = 'https://g.page/r/CbeNfU0EirsoEBE/review',
}

export enum EJobType {
  fullTime = 0,
  partTime = 1,
  Seasonal = 3,
  SelfEmployed = 5,
  Unemployed = 6,
}

export const EJobTypeList = Object.values(EJobType).filter((x) => typeof x === 'number')

export enum ETermPersonalLoan {
  twelve = 12,
  twentyFour = 24,
  thirtySix = 36,
  fortyEight = 48,
  sixty = 60,
  seventyTwo = 72,
}

export enum EBeneficiaryType {
  Applicant = 1,
  Coapplicant = 2,
  Other = 3,
}

export enum EPaymentMethods {
  check = 'check',
  bankTransfer = 'bankTransfer',
}

export enum EExternalStep {
  BankAccount = 'bankAccount',
}

export enum ERequiredExternalStepStatus {
  WaitingForClient = 'notStarted',
  WaitingForBank = 'inProgress',
  Completed = 'completed',
}

export enum ELoanPurpose {
  Veterinary = 'veterinary',
  Medical = 'medical',
  Aesthetics = 'aesthetics',
  HomeImprovement = 'homeImprovement',
  Dentistry = 'dentistry',
  GoodsAndServices = 'goodsAndServices',
  Other = 'other',
}

export enum EDecision {
  Approved = 'approved',
  Partial = 'partial',
  Refused = 'refused',
  Error = 'error',
  Pending = 'pending',
  ManualRevision = 'manual-revision',
}

export enum ECreditApplicationStatus {
  Cancelled = 'cancelled',
  Completed = 'completed',
}

export enum EPrequalificationDecision {
  Approved = 'approved',
  Pending = 'pending',
  Refused = 'refused',
  Error = 'error',
}

export enum EFinalDecision {
  Approved = 'approved',
  Pending = 'pending',
  Refused = 'refused',
  ManualRevision = 'manual-revision',
  Error = 'error',
}

export enum EQuestionType {
  SingleChoice = 'singleChoice',
}

export enum ELanguage {
  French = 0,
  English = 1,
}

export const ELanguageList = Object.values(ELanguage).filter((x) => typeof x === 'number')

export enum EConsignoAction {
  StatusUpdate = 'consigoStatus',
}

export enum EApplicantType {
  Applicant = 'applicant',
  CoApplicant = 'coApplicant',
}

export enum ERelationToApplicant {
  Spouse = 'SP',
  CommonLaw = 'CL',
  Employer = 'Employer',
  InLaw = 'IL',
  Parental = 'PR',
  GrandMother = 'GM',
  GrandFather = 'GF',
  Brother = 'B',
  Sister = 'S',
  Other = 'Other',
}

export const ERelationToApplicantList = Object.values(ERelationToApplicant)

export enum EHomeFeeType {
  Rent = 'rent',
  Own = 'own',
  OwnWithMortgage = 'ownWithMortgage',
  WithParents = 'withParents',
}

export const EHomeFeeTypeList = Object.values(EHomeFeeType)

export enum EGender {
  Male = 0,
  Female = 1,
  Other = 2,
}

export const EGenderList = Object.values(EGender).filter((x) => typeof x === 'number')

export enum EProvince {
  quebec = 'QC',
  ontario = 'ON',
  novaScotia = 'NS',
  newBrunswick = 'NB',
  princeEdwardIsland = 'PE',
  newfoundland = 'NL',
  britishColumbia = 'BC',
  saskatchewan = 'SK',
  alberta = 'AB',
  manitoba = 'MB',
  yukon = 'YT',
  nunavut = 'NU',
  northwestTerritories = 'NT',
}

export const EProvinceList = Object.values(EProvince)

export enum EDocumentStatus {
  AwaitingDocument = 'awaitingDocument',
  AwaitingApproval = 'awaitingApproval',
  Incomplete = 'incomplete',
  Refused = 'refused',
  Approved = 'approved',
}

export enum EWorksheetStatus {
  Draft = 'draft',
  Active = 'active',
  Completed = 'completed',
}

export enum EDocumentType {
  SecondIdWithPhoto = 33,
  ValidDriverLicense = 41,
  SignedCVT = 47,
  AuditCVT = 49,
  ValidPhotoId = 67,
  LastPayStubJob = 17,
  FamilyAllocationsProof = 68,
  WorkIncidentIndemnisationProof = 69,
  MaternityPaternityIncomeProof = 70,
  OtherIncomeProof = 71,
  RetirementIncomeProof = 72,
  DisabilityIncomeProof = 73,
  AssistanceProof = 74,
  EmployementInsuranceProof = 75,
  T4 = 37,
  T4E = 38,
  NoticeOfAssessment = 58,
  ProofOfIncome = 59,
  ProofOfBenefits = 25,
  ChildTaxBenefitStatement = 53,
  MaternityLeaveBenefit = 21,
}

export enum EIncomeProofType {
  LastPayStubJob = 17,
  FamilyAllocationsProof = 68,
  WorkIncidentIndemnisationProof = 69,
  MaternityPaternityIncomeProof = 70,
  OtherIncomeProof = 71,
  RetirementIncomeProof = 72,
  DisabilityIncomeProof = 73,
  AssistanceProof = 74,
  EmployementInsuranceProof = 75,
  T4 = 37,
  T4E = 38,
  NoticeOfAssessment = 58,
  ProofOfIncome = 59,
  ProofOfBenefits = 25,
  ChildTaxBenefitStatement = 53,
  MaternityLeaveBenefit = 21,
}

export enum EExecutionStatus {
  NotStarted = 'notStarted',
  InProgress = 'inProgress',
  Completed = 'completed',
  Error = 'error',
}

export enum EPromoCodeStatus {
  Invalid = 'invalid',
  Expired = 'expired',
  Valid = 'valid',
}

export enum ETrackingEvents {
  GetStarted = 'get_started',

  PrequalSuccess = 'prequal_success',
  PrequalDeclined = 'prequal_declined',

  QualifSuccess = 'qualif_success',
  QualifDeclined = 'qualif_declined',

  WithdrewManualRevision = 'withdrew_manual_revision',
  WithdrewAfterPrequal = 'withdrew_after_prequal',
  WithdrewAfterQualif = 'withdrew_after_qualif',
}

export enum EConsignoEventStatus {
  Success = 'success',
  Decline = 'decline',
}

export enum EFlinksEventSteps {
  SESSION_STORAGE_BLOCKED = 'SESSION_STORAGE_BLOCKED',
  SESSION_STORAGE_BLOCKED_RETRY = 'SESSION_STORAGE_BLOCKED_RETRY',
  INSTITUTION_SELECTED = 'INSTITUTION_SELECTED',
  COMPONENT_LOAD_CREDENTIAL = 'COMPONENT_LOAD_CREDENTIAL',
  COMPONENT_ACCEPT_CONSENT = 'COMPONENT_ACCEPT_CONSENT',
  COMPONENT_DENY_CONSENT = 'COMPONENT_DENY_CONSENT',
  COMPONENT_ACCEPT_TERMS = 'COMPONENT_ACCEPT_TERMS',
  COMPONENT_DENY_TERMS = 'COMPONENT_DENY_TERMS',
  COMPONENT_CLICK_TERMS_URL = 'COMPONENT_CLICK_TERMS_URL',
  SUBMIT_CREDENTIAL = 'SUBMIT_CREDENTIAL',
  RETRY_COUNT = 'RETRY_COUNT',
  MAXIMUM_RETRY_REACHED = 'MAXIMUM_RETRY_REACHED',
  QUESTION_NOT_FOUND = 'QUESTION_NOT_FOUND',
  COMPONENT_LOAD_MFA = 'COMPONENT_LOAD_MFA',
  SUBMIT_MFA = 'SUBMIT_MFA',
  SUBMIT_GET_MFA_QUESTIONS = 'SUBMIT_GET_MFA_QUESTIONS',
  ENHANCED_MFA_HAS_QUESTIONS = 'ENHANCED_MFA_HAS_QUESTIONS',
  ENHANCED_MFA_NO_QUESTIONS = 'ENHANCED_MFA_NO_QUESTIONS',
  SUBMIT_ANSWER_MFA_QUESTIONS = 'SUBMIT_ANSWER_MFA_QUESTIONS',
  ENHANCED_MFA_SUCCESS = 'ENHANCED_MFA_SUCCESS',
  SKIP_ENHANCED_MFA = 'SKIP_ENHANCED_MFA',
  COMPONENT_LOAD_ACCOUNT_SELECTION = 'COMPONENT_LOAD_ACCOUNT_SELECTION',
  COMPONENT_LOAD_INSTITUTION_SELECTOR = 'COMPONENT_LOAD_INSTITUTION_SELECTOR',
  ACCOUNT_SELECTED = 'ACCOUNT_SELECTED',
  REDIRECT = 'REDIRECT',
}

export enum ESupplementalLoanRefusalReasons {
  UnderMinimalAmount = 'UnderMinimalAmount',
  CreditRepportExpired = 'CreditRepportExpired',
  DocumentsAreScanning = 'DocumentsAreScanning',
  OnGoingCreditApplication = 'OnGoingCreditApplication',
}

export const Constants = {
  FinanceFeeRateForRegular: 0.06,
  UpperThresholdAgeForApplicant: 75,
  UpperThresholdAgeForCoapplicant: 75,
  BaseMinTerm: 12,
  BaseMaxTerm: 72,
  CorrectedMaxTerm: 60,
  SupportedDocumentTypes: '.jpg, .jpeg, .png, .pdf, .heic, .heif',
  UncoveredProvinces: [
    EProvince.yukon,
    EProvince.nunavut,
    EProvince.northwestTerritories,
    EProvince.princeEdwardIsland,
  ],
  monthNames: {
    1: { nameEn: 'January', nameFr: 'Janvier' },
    2: { nameEn: 'February', nameFr: 'Février' },
    3: { nameEn: 'March', nameFr: 'Mars' },
    4: { nameEn: 'April', nameFr: 'Avril' },
    5: { nameEn: 'May', nameFr: 'Mai' },
    6: { nameEn: 'June', nameFr: 'Juin' },
    7: { nameEn: 'July', nameFr: 'Juillet' },
    8: { nameEn: 'August', nameFr: 'Août' },
    9: { nameEn: 'September', nameFr: 'Septembre' },
    10: { nameEn: 'October', nameFr: 'Octobre' },
    11: { nameEn: 'November', nameFr: 'Novembre' },
    12: { nameEn: 'December', nameFr: 'Décembre' },
  } as { [month: number]: { nameEn: string; nameFr: string } },
  IncomeProofTypes: [
    EDocumentType.LastPayStubJob,
    EDocumentType.FamilyAllocationsProof,
    EDocumentType.WorkIncidentIndemnisationProof,
    EDocumentType.MaternityPaternityIncomeProof,
    EDocumentType.OtherIncomeProof,
    EDocumentType.RetirementIncomeProof,
    EDocumentType.DisabilityIncomeProof,
    EDocumentType.AssistanceProof,
    EDocumentType.EmployementInsuranceProof,
    EDocumentType.T4,
    EDocumentType.T4E,
    EDocumentType.NoticeOfAssessment,
    EDocumentType.ProofOfIncome,
    EDocumentType.ProofOfBenefits,
    EDocumentType.ChildTaxBenefitStatement,
    EDocumentType.MaternityLeaveBenefit,
  ],
  ExpectingDocumentStatuses: [EDocumentStatus.AwaitingDocument, EDocumentStatus.Incomplete, EDocumentStatus.Refused],
  MinIncomes: 21000,
  MinLoanAmount: 500,
  MaxLoanAmount: 40000,
  MaxRate: 29.99,
}
