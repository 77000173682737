import {
  DeleteBankAccountRequestDto,
  FilteredApplicant,
  FilteredCreditApplication,
  SkipBankAccountRequestDto,
  useContinueWithComputedIncome,
  useResetApplicantBankRequest,
  useSkipApplicantBankRequest,
} from '@src/api/credit-api'
import {
  hasApplicantAskedToSkipBankRequest,
  hasApplicantCompletedFlinks,
  hasApplicantSubmittedAllRequiredIncomeDocuments,
  hasApplicantSubmittedFlinks,
} from '@src/data/credit-application-selectors'
import { EApplicantType, RequiredDocumentWithStatus } from '@src/types'

import { useMerchantById } from '@src/api/merchants-api'
import { AsyncActionButton, Loader } from '@src/components'
import BackButton from '@src/components/BackButton'
import RequiredDocumentTable from '@src/components/RequiredDocumentTable'
import { reportErrorToConsole } from '@src/services/error-logger'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { FormatCurrency } from '@src/services/Formatter'

type Props = {
  creditApp: FilteredCreditApplication
  applicantType: EApplicantType
  skipBankAccountRequiredDocuments: RequiredDocumentWithStatus[]
}

const ApplicantIncomePage = ({ creditApp, applicantType, skipBankAccountRequiredDocuments }: Props) => {
  const { i18n, t } = useTranslation()
  const navigate = useNavigate()
  const [merchant, isLoadingMerchant] = useMerchantById(creditApp.merchantId)
  const applicant =
    applicantType === EApplicantType.Applicant ? creditApp.applicant : (creditApp.coapplicant as FilteredApplicant)
  const hasSkippedBankRequest = hasApplicantAskedToSkipBankRequest(
    creditApp,
    applicantType,
    skipBankAccountRequiredDocuments,
  )

  const applicantSkipBankRequestRequiredDocs = skipBankAccountRequiredDocuments.filter(
    (x) => x.applicantType === applicantType,
  )
  const hasApplicantFinishedFlinks = hasApplicantCompletedFlinks(creditApp, applicantType)
  const hasApplicantSubmittedFlinksRequest = hasApplicantSubmittedFlinks(creditApp, applicantType)

  const areIncomeDocsSubmitted = hasApplicantSubmittedAllRequiredIncomeDocuments(
    skipBankAccountRequiredDocuments,
    applicantType,
  )
  const areIncomePendingReview =
    !applicant.areIncomesConfirmed &&
    (areIncomeDocsSubmitted || (hasApplicantSubmittedFlinksRequest && !hasApplicantFinishedFlinks))

  const [resetBankRequest, isResettingBankRequest] = useResetApplicantBankRequest()
  const [addRequiredDocumentsToSkipBankRequest, isAddingRequiredDocuments] = useSkipApplicantBankRequest()
  const [continueWithComputedIncomeRequest, isContinueWithComputedIncomeRequest] = useContinueWithComputedIncome()

  const openFlinks = () => {
    navigate(`/creditapplication/${creditApp.id}/flinks/${applicantType}`)
  }

  const changeBankAccount = () => {
    const dto: DeleteBankAccountRequestDto = {
      id: creditApp.id,
      applicantType,
    }
    resetBankRequest(dto)
      .then(() => openFlinks())
      .catch(reportErrorToConsole)
  }

  const continueWithComputedIncome = () => {
    const dto = {
      id: creditApp.id,
      applicantType,
    }
    continueWithComputedIncomeRequest(dto)
      .then(() => {
        navigate(`/creditapplication/${creditApp.id}`)
      })
      .catch(reportErrorToConsole)
  }

  const submitApplicantIncomeProofs = () => {
    const dto: SkipBankAccountRequestDto = {
      id: creditApp.id,
      applicantType,
    }
    addRequiredDocumentsToSkipBankRequest(dto).catch(reportErrorToConsole)
  }

  if (applicant.areIncomesConfirmed)
    return (
      <main className="general-message">
        <div className="paragraph full-width-center-container ">
          <h4 className="subtitle">{t('common.incomeVerification')}</h4>
          <h3>{t('incomeTile.confirmed')}</h3>
        </div>
        <div className="btn-group centered-one-btn-group full-width-mobile">
          <BackButton />
        </div>
      </main>
    )

  if (areIncomePendingReview)
    return (
      <main className="general-message">
        <div className="paragraph full-width-center-container ">
          <h4 className="subtitle">{t('common.incomeVerification')}</h4>
          <h3>{t('incomeTile.underReview')}</h3>
        </div>
        <div className="btn-group centered-one-btn-group full-width-mobile">
          <BackButton />
        </div>
      </main>
    )

  if (hasApplicantFinishedFlinks && !applicant.areFlinksIncomeMatchingDeclared && !areIncomeDocsSubmitted)
    return (
      <main className="general-message" style={{ display: 'block' }}>
        <div className="paragraph">
          <h4 className="subtitle">{t('common.incomeVerification')}</h4>
          <h1>{t('common.oopsTitle')}</h1>
          <p className="black-strong">
            <strong>
              <Trans i18nKey="incomeTile.mismatch" />
            </strong>
          </p>
          <ul id="applicant-incomes-options">
            <Trans>
              <li className="black-strong">
                <Trans i18nKey="incomeTile.useOtherAccount" />
              </li>
              {applicant.canContinueWithComputedIncome && (
                <li>
                  <Trans
                    i18nKey="incomeTile.useComputedIncome"
                    values={{ computedIncome: FormatCurrency(applicant.computedIncome) }}
                  />
                </li>
              )}
              <li className="black-strong">
                <Trans i18nKey="incomeTile.submitAdditionalIncomeDoc" />
              </li>
            </Trans>
          </ul>
          <div className="btn-group mobile-reversed" style={{ marginBottom: '6rem' }}>
            <BackButton />
            <AsyncActionButton
              isPending={isResettingBankRequest}
              withConfirm
              primary
              onClick={changeBankAccount}
              className="btn-blue btn-simple"
            >
              {t('incomeTile.useOtherAccountBtn')}
            </AsyncActionButton>
            {applicant.canContinueWithComputedIncome && (
              <AsyncActionButton
                isPending={isContinueWithComputedIncomeRequest}
                withConfirm
                primary
                onClick={continueWithComputedIncome}
                className="btn-blue btn-simple"
              >
                {t('incomeTile.useComputedIncomeBtn')}
              </AsyncActionButton>
            )}
          </div>
          <RequiredDocumentTable
            creditApplicationId={creditApp.id}
            applicant={`${applicant.firstName} ${applicant.lastName}`}
            requiredDocuments={applicantSkipBankRequestRequiredDocs}
          />
        </div>
      </main>
    )

  if (hasSkippedBankRequest && !areIncomeDocsSubmitted)
    return (
      <main className="general-message">
        <div className="paragraph">
          <h4 className="subtitle">{t('common.incomeVerification')}</h4>
          <p>{t('incomeTile.skippedBankReq')}</p>
          <RequiredDocumentTable
            creditApplicationId={creditApp.id}
            applicant={`${applicant.firstName} ${applicant.lastName}`}
            requiredDocuments={applicantSkipBankRequestRequiredDocs}
          />

          <div className="btn-group mobile-reversed">
            <BackButton />
            <AsyncActionButton onClick={openFlinks} isPending={false}>
              {t('incomeTile.useFlinks')}
            </AsyncActionButton>
          </div>
        </div>
      </main>
    )

  if (isLoadingMerchant || !merchant)
    return <Loader title={t('common.withYouShortly')} description={t('common.pleaseWait')} />

  return (
    <main className="general-message">
      <h4 className="subtitle">{t('common.incomeVerification')}</h4>
      <div className="applicationInfo-card">
        <div className="paragraph">
          <div>
            {t('incomeTile.preventFraud')}
            {merchant.canSkipFlinks && t('incomeTile.methodOptions')}
            {!merchant.canSkipFlinks && (
              <>
                &nbsp;{t('incomeTile.flinksDesc')}&nbsp;<strong>{t('incomeTile.flinksSafe')}</strong>
              </>
            )}
          </div>
          <br />
          {merchant.canSkipFlinks && (
            <ol id="flinks-options">
              <li>
                <div>
                  {t('incomeTile.flinksOption')} {t('incomeTile.flinksDesc')}{' '}
                  <strong>{t('incomeTile.flinksSafe')}</strong>
                </div>
              </li>
              <br />
              <li>
                <div>
                  {t('incomeTile.manualOption')}
                  <ul>
                    {applicantSkipBankRequestRequiredDocs.map((d) => {
                      return <li key={d.id}>{i18n.language === 'fr' ? d.nameFr : d.nameEn}</li>
                    })}
                    {applicantType === EApplicantType.Applicant && <li>{t('incomeTile.voidCheque')}</li>}
                  </ul>
                </div>
              </li>
            </ol>
          )}
        </div>
        <div className="btn-group">
          <AsyncActionButton primary onClick={openFlinks} isPending={false}>
            {t('incomeTile.useFlinks')}
          </AsyncActionButton>
          {merchant.canSkipFlinks && (
            <AsyncActionButton primary onClick={submitApplicantIncomeProofs} isPending={isAddingRequiredDocuments}>
              {t('incomeTile.manualUpload')}
            </AsyncActionButton>
          )}
        </div>
      </div>
      <BackButton />
    </main>
  )
}

export default ApplicantIncomePage
